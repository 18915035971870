import React from "react"
import { PageLayout } from "@components/layouts"
import SEO from "@components/seo"
import TextBlock from "@components/textBlock"
import SingleColumnBlock from "@components/singleColumnBlock"
import PageWrapper from "@components/pageWrapper"
import Blockquote from "@components/blockquote"

import { VideoPlayer } from "@components/video"

import {
  FormWrapper,
  RequestMoreInfo,
} from "@components/Forms"

const LPStyleGuitar = () => {

  return (
    <PageLayout>
      <SEO 
          title="LP-Style Guitar | Futura | King Electric Guitars"
          ogDescription="The Futura is an LP-style guitar from King Electric Guitars made of Spanish cedar, hybrid set-neck bolt on construction with a variety of customization options." 
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <img
              src="/images/futura-lp-style.jpg"
              alt="Futura LP-Style Guitar - King Electric Guitars"
            />
          </div>
        }
        textRight={
          <div>
            <h1>
              Futura LP-Style Electric Guitar
            </h1>
            <p>
              When designing the Futura, I pulled inspiration from a car - the 1963 Falcon Futura. My dad and I owned a '63 Falcon Futura convertible. I based the top horn of the guitar around the curve right above the taillight.
            </p>
            <p>
              I've always been into cars. When I was a kid my next-door neighbor was this super cool dude. He had a jacked-up Camaro and he played a Les Paul through a triple stack of Marshalls. Somehow, that's always bled into what I wanted my LP-style guitar to look like. The Futura has that jacked-up 70s vibe.
            </p>
            <p>Starting at <span className="green bold">$2500</span></p>
            <img
              src="/images/futura-car.jpg"
              alt="Futura Car Inspiration - King Electric Guitars"
            />
          </div>
        }
      />
      <div className="section textured">
        <TextBlock
          textPadded
          textLeft={
            <div>
              <img
                src="/images/futura-design.jpg"
                alt="LP-Style Guitar Influence - King Electric Guitars"
              />
            </div>
          }
          textRight={
        <div>
          <h2>
            Improving Upon The Classics
          </h2>
          <p>
            When you think of rock music, what guitar comes to mind? I bet you thought of an Les Paul style guitar. It's a classic. I put my own flare on classic designs. With the Futura, I wanted a unique guitar design that melds Jimmy Page with Johnny Winter and sprinkles a little Pete Townshend in. I wanted a single cutaway solid body influenced by the best Les Paul guitars mixed with influence from other models. I took additional design cues from the Jazzmaster and Firebird - looking at what I liked about those guitars. But more importantly, I wanted to fix what I didn’t like in those designs.
          </p>
          <p>
            I wanted to solve some of the issues with tuning stability and neck fragility. I always felt like Gibson Les Paul guitar bodies needed to be a little less symmetrical. I'm a big guy, so they also looked a bit small on me and my taller guitarist friends. So, I wanted to make something a little bigger than a typical LP style guitar. So the Futura is almost the size of a Jazzmaster. There’s also a little bit of a nod to the Firebird.
          </p>
          <FormWrapper
            modal
            buttonProps={{
              content: "Reach Out. Let's Talk!",
              size: "tiny",
              icon: "chat",
            }}
          >
            <RequestMoreInfo />
          </FormWrapper>
        </div>
          }
        />
      </div>
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h2>
              Raised Center Section
            </h2>
            <p>
              I’ve always loved guitars with raised center sections. Interestingly, that idea comes not from a Firebird. I first saw this on a Robin Machete played by J Yeunger of White Zombie. I was in love with that weird guitar before I even knew what a Firebird was. It had that ugly V headstock. I always thought that was the coolest guitar ever.
            </p>
          </div>
        }
        textRight={
          <div>
            <img
              src="/images/futura-raised-center.jpg"
              alt="Futura Raised Center - King Electric Guitars"
            />
          </div>
        }
      />
      <PageWrapper color="grey">
        <TextBlock
          textPadded
          textLeft={
            <div>
              <img
                src="/images/futura-spanish-cedar.jpg"
                alt="Spanish Cedar Body and Neck"
              />
            </div>
          }
          textRight={
            <div>
              <h2>
                Spanish Cedar Tonewood
              </h2>
              <p>
                I build the standard Futura model from Spanish Cedar. The wood has the look of a mahogany body guitar but it’s much lighter. So even though the Futura is larger than a Les Paul, it still weighs less. It even weighs less than most Telecasters. A lot of folks hate playing Les Pauls because it kills their shoulder. Spanish Cedar solves that. You could play a Futura all night long. Despite being slightly larger fits into a standard guitar case, and a hardshell case comes standard.
              </p>
              <h3>
                No Neck Dive
              </h3>
              <p>
                A common issue with Firebird, SG, and Les Paul Junior guitars is neck dive. If you let go of the neck while standing, down it goes. So, the balance of the Futura design was important to me. Put it on and it stays in place.
              </p>
            </div>
          }
        />
      </PageWrapper>
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h2>
              Bolt-On Set Neck Hybrid
            </h2>
            <p>
              So, I construct the Futura like a set-neck guitar, but it's bolted on.
            </p>
            <p>
              Most LP-style guitars are set-necks. My time doing guitar neck repair forced me to think about the problems with this style of build and make improvements upon the LP neck. Having the extra wood on the heel is a good thing. The rigidity of a glued neck is also good. But it does make servicing the guitar or making hardware changes more challenging. It's so much easier to work on a neck removed from the body.
            </p>
            <p>
              The reason a lot of people prefer a set-neck is they feel that bolt-on necks have too much “slop”. But since I construct the Futura like a set-neck, the neck pocket is much deeper than a standard Fender bolt-on. And when I say bolt-on, I mean it. I use inserts and threaded screws. Not wood screws. So the neck is as tight as being glued. There is no slop in the neck pocket whatsoever. I’m milling these things as if I was going to glue it, but bolting it in so we have more flexibility for setup and service. If you ever changed the bridge to something that requires the neck to tilt back more, no problem.
            </p>
            <FormWrapper
              modal
              buttonProps={{
                content: "Reach Out. Let's Talk!",
                size: "tiny",
                icon: "chat",
              }}
            >
              <RequestMoreInfo />
            </FormWrapper>
          </div>
        }
        textRight={
          <div>
            <img
              src="/images/futura-hybrid-set-neck-bolt-on.jpg"
              alt="Hybrid Set Neck and Bolt-On"
            />
            <h3>
              Carved Heel For High Neck Access
            </h3>
            <p>
              I don’t use a neck plate. I'm actually insetting ferrules for machine screws. Once I get my ferrules exactly where they need to be, I'm carving as much as I possibly can out of the body joint. You have a much more accessible heel than you would on a regular set neck guitar with a standard joint. If you want, you can play a note on the neck pickup of the Futura.
            </p>
          </div>
        }
      />
      <div className="section textured">
        <TextBlock
          textPadded
          textLeft={
            <div>
              <img
                src="/images/futura-tilt-back-headstock.jpg"
                alt="8 Degree Tilt-Back Headstock - King Electric Guitars"
              />
            </div>
          }
          textRight={
        <div>
          <h2>
            LP-Style Tilt-Back Head Stock That Won’t Easily Break
          </h2>
          <p>
            The problem with a lot of LP-style guitars is that you have a tilted back headstock. So the connecting point of the neck and headstock is already weak. And then you're routing out over half the wood out to put a truss rod in. So you have very little wood holding your headstock on. That's one reason why they snap.
          </p>
          <p>
            The Futura truss rod does not go through the headstock. The truss rod goes from below the nut down to the end of the fretboard. You actually adjust it through a spoke wheel in the last fret of the fretboard.
          </p>
          <p>
            The other reason is the standard Gibson-style and Epiphone Les Paul headstock angles are entirely too steep. They go anywhere from 13 to 17 degrees. The Futura has an 8-degree headstock tilt. This provides enough break angle at the nut for proper down pressure. Yet, if you lay the guitar flat, the headstock doesn't even touch.
          </p>
        </div>
          }
        />
      </div>
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h2>
              Binding
            </h2>
            <p>
              The raised center section on the Futura is bound in a unique way. The guitar is bound all the way around on the face. Where the raised section is, the binding is thicker on the outside edges of the guitar. And then the raised section of that is bound on the sides as well.
            </p>
            <FormWrapper
              modal
              buttonProps={{
                content: "Reach Out. Let's Talk!",
                size: "tiny",
                icon: "chat",
              }}
            >
              <RequestMoreInfo />
            </FormWrapper>
          </div>
        }
        textRight={
          <div>
            <img
              src="/images/futura-binding.png"
              alt="Futura Guitar Binding"
            />
          </div>
        }
      />
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h3>
              LP-Style Bridge Options
            </h3>
            <p>
              My standard bridge on the Futura is a Hipshot hardtail fixed bridge. It solves some problems with a Tune-o-matic bridge. One benefit is I don't need to angle the neck back. It also has six individual saddles that I can raise, lower, and intonate individually. 
            </p>
            <p>
              Optionally, the Futura can come with an all-in-one wraparound tailpiece bridge or the Hipshot Baby Grand Bridge. If you wanted a tremolo, a Duesenberg Les Trem II would work great.
            </p>
            <h3>
              Dual P-90 or Humbuckers
            </h3>
            <p>
              The standard version of the Futura has two humbucker-size routes. I love the sound of the Bootstrap "Squeaky Clean" P-90s, which come in a humbucker size. But any humbucker pickups will work too. I can also do soapbar p-90 routes for a bit more cost.
            </p>
          </div>
        }
      />
      <PageWrapper color="grey">
        <TextBlock
          textPadded
          textLeft={
            <div>
              <img
                src="/images/futura-electronics.jpg"
                alt="Futura Guitar Electronics"
              />
            </div>
          }
          textRight={
            <div>
              <h2>
                Electronics Wiring
              </h2>
              <p>
                The Futura comes standard with two volume pots, one master tone, and a three-way switch. For p-90's, I use an orange drop .022 capacitor which is the same as the Les Paul Junior. I can do modern or 50s wiring depending on your preferences.
              </p>
              <h2>
                Finish Options
              </h2>
              <p>
                There are endless custom finishing options. The Spanish Cedar would look beautiful simply rubbed in tung oil. It could be translucent, sunburst, etc. I can also do fabric tops. I can do any type of paint you want. That includes candy paints like you see on a lot of hotrod cars.
              </p>
              <h2>
                Memorial Finish
              </h2>
              <p>
                Because Spanish Cedar has pores, I’m offering a special Memorial Finish. I can incorporate the ashes of someone who was important to you. Or even a pet. I mix the ashes with a grain filler that pushes the ash into the pores. You'll be able to see them though the finish. That's what I did on the Futura guitars I made for my brother and myself which you can see in the video below.
              </p>
            </div>
          }
        />
      </PageWrapper>
      <div className="section textured" id="videoone">
          <VideoPlayer youtubeId='DVuWXmwAk9A' />
          <VideoPlayer youtubeId='GUZrCoLVwO4' />
          <VideoPlayer youtubeId='CN3pwhMtnLk' />
      </div>
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h4>Get in touch. Let's talk about building your dream guitar!</h4>
            <RequestMoreInfo />
          </div>
        }
      />
    </PageLayout>
  )
}

export default LPStyleGuitar
